import React, { useEffect } from "react";
import Mirador from "mirador";
import "../styles/mirador-custom.css";

// Import annotation plugin from node_modules
import annotationPlugins from "mirador-annotations";

// Import the SimpleAnnotationServerV2Adapter
import SimpleAnnotationServerV2Adapter from "./SimpleAnnotationServerV2Adapter";


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const iiif = urlParams.get('iiif-manifest')
console.log(iiif);
const MiradorComponent = ({ theme }) => {
  useEffect(() => {
    // Configuration for Mirador viewer
    const config = {
      annotation: {
        // Set adapter for annotations to the SAS adapter
        adapter: (canvasId) =>
          new SimpleAnnotationServerV2Adapter(
            canvasId,
            "https://prodem.muruca.cloud/wp-json/muruca-core-v2/v1/muruca-iiif/annotation"
          ),
        // Set to true to display annotation JSON export button
        exportLocalStorageAnnotations: true,
      },
      window: {
        // Set default sidebar panel to annotations
        defaultSideBarPanel: "annotations",
        // Open sidebar by default
        sideBarOpenByDefault: true,
      },
      id: "mirador-container", // ID of the Mirador container
      windows: [
        {
          // Enable image tools
          imageToolsEnabled: true,
          // Manifest ID of the IIIF manifest to load
          manifestId:
          iiif
          //"https://prodem.muruca.cloud/wp-json/muruca-core-v2/v1/muruca-iiif/152/manifest.json"
          //"https://prodem.muruca.cloud/wp-json/muruca-core-v2/v1/muruca-iiif/test/manifest.json"
            // "https://media.nga.gov/public/manifests/nga_highlights.json",
            // "http://127.0.0.1:8080/manifest_structures.json", // TEST MANIFEST v2 ON "SIMPLE WEB SERVER"
            //"https://raw.githubusercontent.com/sterenz/TEST_MANIFEST/main/manifests/manifest_structures_annotations_v3.json", // TEST MANIFEST v3 WITH ANNOTATIONS INSIDE"
          // "https://raw.githubusercontent.com/sterenz/TEST_MANIFEST/main/manifests/manifest_structures_v3.json", // TEST MANIFEST v3 ON "SIMPLE WEB SERVER"
        },
      ],
      ...theme, // Merge the theme configuration
    };

    // Initialize Mirador viewer with configuration and annotation plugins
    Mirador.viewer(config, [...annotationPlugins]);
  }, [theme]); // Run effect when theme changes

  // Render the container for Mirador viewer
  return <div id="mirador-container"></div>;
};

export default MiradorComponent;
