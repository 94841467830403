import React from "react";
import MiradorViewer from "./components/MiradorViewer";
import { customTheme } from "./themes/customTheme";

const App = () => {
  return (
    <main className="App" style={{ padding: "20px" }}>
    
      <h1>Mirador annotator</h1>
      
      <MiradorViewer theme={customTheme} />
    </main>
  );
};

export default App;
